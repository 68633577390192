import React from 'react';
import { Box, Typography, Container, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logo from '../assets/img/logo.svg';

const SupportPage = () => {
  return (
    <Container 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh' 
      }}
    >
      <Box sx={{ textAlign: 'center', mt: 8 }}>
      <img src={logo} alt="Raoul Logo" style={{ width: '140px' }} />
        <Typography variant="h4" component="p" sx={{ mt: 2 }}>
          Nous sommes là pour vous aider. 
        </Typography>
      </Box>
      <Box sx={{ mt: 1, width: '100%', maxWidth: 600, textAlign: 'center' }}>
        <Button variant="contained" color="primary" href="mailto:cool@heyraoul.fr" fullWidth sx={{ mt: 2 }}>
          Contacter le support
        </Button>
      </Box>
      <Box sx={{ mt: 4, width: '100%', maxWidth: 600 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          FAQ
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Problème de connexion</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Si vous rencontrez des problèmes de connexion, vérifiez d'abord que vous avez entré les bonnes informations de connexion. Si le problème persiste, essayez de réinitialiser votre mot de passe ou contactez notre support.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Comment réinitialiser mon mot de passe ?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Pour réinitialiser votre mot de passe, cliquez sur "Mot de passe oublié" sur la page de connexion et suivez les instructions pour réinitialiser votre mot de passe via votre adresse email.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Application lente ou ne répond pas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Si l'application est lente ou ne répond pas, essayez de fermer et de rouvrir l'application. Assurez-vous également que votre appareil dispose de la dernière mise à jour du système et que l'application est à jour.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ marginTop: 4, textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">
          &copy; 2024 Raoul. Tous droits réservés.
        </Typography>
      </Box>
    </Container>
  );
};

export default SupportPage;
