import React from 'react';
import { AppBar, Toolbar, Button, Box, Typography, Container } from '@mui/material';
import logo from '../assets/img/logo.svg';

const LandingPage = () => {
  const openApp = () => {
    window.location.href = 'raoulapp://deep-link/test';
  };  

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Button color="inherit" onClick={openApp}>
            Ouvrir l'application
          </Button>
        </Toolbar>
      </AppBar>
      <Container 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '100vh' 
        }}
      >
        <Box sx={{ textAlign: 'center', mt: 8 }}>
          <img src={logo} alt="Raoul Logo" style={{ width: '140px' }} />
          <Typography variant="h5" component="p" sx={{ mt: 3 }} gutterBottom>
            Rejoins les testeurs les plus zen de l'univers :
          </Typography>
        </Box>
        <Box sx={{ mt: 0, width: '100%', maxWidth: 500 }}>
          <iframe 
            src="https://cdn.forms-content-1.sg-form.com/79b770e3-296b-11ef-ad1c-aaadc6892509" 
            style={{ border: 'none', width: '100%', minHeight: '55vh' }} 
            title="Formulaire de collecte de mail"
          />
        </Box>
        <Box sx={{ marginTop: 2, width: '100%', maxWidth: 500 }}>
        </Box>
        <Box sx={{ marginTop: 2, textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            &copy; 2024 Raoul. Tous droits réservés.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default LandingPage;
