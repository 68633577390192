import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider, Container } from '@mui/material';
import theme from './theme';
import LandingPage from './views/LandingPage';
import SupportPage from './views/SupportPage';
import PrivacyPage from './views/PrivacyPage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
        </Routes>
      </Container>
    </ThemeProvider>
  );
}

export default App;
